<template>
<div class="table-content">
        <div class="table-options">
            <div class="delete" v-if="selected.length > 0">
                <vs-icon icon="delete"></vs-icon>
            </div>
        </div>
        <div class="table-wrapper with-border">
            <vs-table :data="bookings">
                <template slot="thead">
                    <vs-th sort-key="SUPPLIER">CODE</vs-th>
                    <vs-th sort-key="SuppName">Name</vs-th>
                    <vs-th sort-key="qty">Qty</vs-th>
                    <vs-th class="center"></vs-th>
                </template>
                <template slot-scope="{ data }">
                    <template :data="tr"  v-for="(tr, indextr) in data">
                        <vs-tr :key="indextr" :class="{'active': opened.includes(data[indextr].id)}">
                            <vs-td :data="data[indextr].SUPPLIER"> 
                                {{ data[indextr].SUPPLIER}}
                            </vs-td>
                            <vs-td :data="data[indextr].SuppName"> 
                                {{ data[indextr].SuppName}}
                            </vs-td>
                           <vs-td :data="data[indextr].qty"> 
                                {{ data[indextr].qty }}
                            </vs-td>
                            <vs-td class="options">
                                <vs-icon icon="fullscreen" @click="getByServTypes(data[indextr].SUPPLIER)"></vs-icon>
                            </vs-td> 
                        </vs-tr>
                    </template>
                </template>
            </vs-table>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableStatsLocations",
    props: {
        items: { type: Array, default: () => [] },
    },
    data () {
        return {
            opened: [],
            selected:[],
            bookings: this.items,
            balance: 0,
            invTotal: 0,
            rcTotal:  0
        }
    },
    watch: {
        items(val){
            this.bookings = val
            
        },
    },
    methods: {
        getByServTypes(code){
            this.$emit('suppCode', code)
        },
        toggleRow(id){
            const index   = this.opened.indexOf(id)
            // this.opened = []
            if (index > -1) {
                this.opened.splice(index, 1)
                this.activeRow = -1
            } else {
                this.opened.push(id)
                this.activeRow = id
            }
        }
    }
}
</script>